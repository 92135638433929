// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cz7Ng_o__", "Rkq1pqzcU", "hzLSeWXF7"];

const serializationHash = "framer-Zw9oC"

const variantClassNames = {cz7Ng_o__: "framer-v-502dq3", hzLSeWXF7: "framer-v-xy618d", Rkq1pqzcU: "framer-v-1r2zadb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 1, ease: [0.12, 0.23, 0.5, 1], type: "tween"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {End: "hzLSeWXF7", Initial: "cz7Ng_o__", Pulse: "Rkq1pqzcU"}

const getProps = ({color, height, id, width, ...props}) => { return {...props, adPC0uzY1: color ?? props.adPC0uzY1 ?? "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64)) /* {\"name\":\"Orange 55\"} */", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "cz7Ng_o__"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, adPC0uzY1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cz7Ng_o__", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1dcgjje = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Rkq1pqzcU"), 1000)
})

const onAppear1b8uz5q = activeVariantCallback(async (...args) => {
await delay(() => setVariant("hzLSeWXF7"), 500)
})

const onAppear1e5u162 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("cz7Ng_o__"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1dcgjje, hzLSeWXF7: onAppear1e5u162, Rkq1pqzcU: onAppear1b8uz5q})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-502dq3", className, classNames)} data-framer-name={"Initial"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"cz7Ng_o__"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({hzLSeWXF7: {"data-framer-name": "End"}, Rkq1pqzcU: {"data-framer-name": "Pulse"}}, baseVariant, gestureVariant)}><motion.div className={"framer-16w9qih"} data-framer-name={"BG Circle"} layoutDependency={layoutDependency} layoutId={"US4UWy7ct"} style={{backgroundColor: adPC0uzY1, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%", opacity: 0}} transformTemplate={transformTemplate1} variants={{Rkq1pqzcU: {opacity: 0.3}}} {...addPropertyOverrides({hzLSeWXF7: {transformTemplate: undefined}, Rkq1pqzcU: {transformTemplate: undefined}}, baseVariant, gestureVariant)}/><motion.div className={"framer-1ievf9e"} data-framer-name={"Circle"} layoutDependency={layoutDependency} layoutId={"hc18nR5ri"} style={{backgroundColor: adPC0uzY1, borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-Zw9oC[data-border=\"true\"]::after, .framer-Zw9oC [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Zw9oC.framer-wk9u48, .framer-Zw9oC .framer-wk9u48 { display: block; }", ".framer-Zw9oC.framer-502dq3 { height: 18px; overflow: hidden; position: relative; width: 18px; }", ".framer-Zw9oC .framer-16w9qih { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); left: 50%; overflow: visible; position: absolute; top: 50%; width: 8px; }", ".framer-Zw9oC .framer-1ievf9e { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 8px); left: 5px; overflow: visible; position: absolute; top: 5px; width: 8px; }", ".framer-Zw9oC.framer-v-1r2zadb.framer-502dq3, .framer-Zw9oC.framer-v-xy618d.framer-502dq3 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 18px); }", ".framer-Zw9oC.framer-v-1r2zadb .framer-16w9qih, .framer-Zw9oC.framer-v-xy618d .framer-16w9qih { height: var(--framer-aspect-ratio-supported, 18px); left: 0px; right: 0px; top: 0px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 18
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Rkq1pqzcU":{"layout":["fixed","fixed"]},"hzLSeWXF7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"adPC0uzY1":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FrameryTWAvP4qV: React.ComponentType<Props> = withCSS(Component, css, "framer-Zw9oC") as typeof Component;
export default FrameryTWAvP4qV;

FrameryTWAvP4qV.displayName = "Helper/Pulse Indicator";

FrameryTWAvP4qV.defaultProps = {height: 18, width: 18};

addPropertyControls(FrameryTWAvP4qV, {variant: {options: ["cz7Ng_o__", "Rkq1pqzcU", "hzLSeWXF7"], optionTitles: ["Initial", "Pulse", "End"], title: "Variant", type: ControlType.Enum}, adPC0uzY1: {defaultValue: "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64)) /* {\"name\":\"Orange 55\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(FrameryTWAvP4qV, [])